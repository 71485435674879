import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

class Registerinspector extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(data,{resetForm}) {
    console.log(JSON.stringify(data, null, 2));
      resetForm({formData: " "})
    let formData = new FormData();
    formData.append('fullname', data.fullname)
    formData.append('username', data.username)
    formData.append('email', data.email)
    formData.append('pwd', data.pwd)

    axios({
      method: 'post',
      url: 'http://backend.pxb0625.uta.cloud/sqlsrv/register_inspector.php',
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
  }).then(response => response.data)
  .then((data) => {
    
    console.log(data)
    if(data == 'ok'){
      alert('user registered successfully');
    
      
      
      }
      else {
        alert('Email already present');
        
      }
      
       
      
  })
  .catch(function (response) {
      //handle error
      console.log(response)
  });
  
	}    
  
validationSchema() {
  return Yup.object().shape({
    fullname: Yup.string().required('Fullname is required'),
    username: Yup.string()
      .required('Username is required')
      .min(6, 'Username must be at least 6 characters')
      .max(20, 'Username must not exceed 20 characters'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    pwd: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
    confirmpassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('pwd'), null], 'Confirm Password does not match'),
    
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  });
}



    
    render() {
      const initialValues = {
        fullname: '',
        username: '',
        email: '',
        pwd: '',
        confirmpassword: '',
        acceptTerms: false,
    };
        return (
            <div>
 <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
            </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="menu"><a href="home">home</a> </li>
                    <li className="menu"> <a href="about">About Us</a> </li>
                    <li className="menu"> <a href="services">Services</a></li>
                    <li className="menu"> <a href="contact">Contact Us</a> </li>
                    <li className="active menu"><a href="login">Login</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          
          <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Register Inspector</h2>
          </div>
          
        </section>
        <section>
          <div className="wrapper">
            <div className="title">
              Registration Form
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}>
 
                {({ resetForm }) => (
                <Form>
            <div className="form">
              <div className="inputfield">
                <label>Full Name</label>
                <Field name="fullname" type="text" className="input"/>
                <ErrorMessage name="fullname" component="div" className="text-danger"/>
                    
              </div>
              <div className="inputfield">
              <label htmlFor="username"> Username </label>
                <Field name="username" type="text" className="input"/>
                <ErrorMessage name="username" component="div" className="text-danger"/>
                   
              </div>
              
              <div className="inputfield">
              <label htmlFor="email"> Email </label>
                <Field name="email" type="text" className="input"/>
                <ErrorMessage name="email" component="div" className="text-danger"/>
              </div>
              <div className="inputfield">
                <label htmlFor="pwd">Password</label>
                <Field name="pwd" type="password" className="input"/>
                <ErrorMessage name="password" component="div" className="text-danger"/>
              </div>
              
              <div className="inputfield">
                <label htmlFor="confirmpassword">confirm password</label>
                <Field name="confirmpassword" type="password" className="input"/>
                <ErrorMessage name="confirmpassword" component="div" className="text-danger"/>
              </div>
              <div className="inputfield terms">
              <Field name="acceptTerms" type="checkbox" className="checkmark"/>
              <label htmlFor="acceptTerms" className="checkmark">
                 I have read and agree to the Terms
                </label>
                <ErrorMessage name="acceptTerms" component="div" className="text-danger"/>
                
              </div>
              <div className="inputfield">
              <button type="submit"  className="btn">
                        Register
                      </button>
              <button type="button" onClick={resetForm} className="btn">
                 Reset
              </button>
              </div>
              <p>Already have an account. <Link to={"/login"}> Login here</Link><br /></p>
            </div>
            </Form>
                )}
                </Formik>
          </div>
        </section>
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
            <div className="col-md-4 col-xs-6 about">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
                <p> <br />
                     <br />
                    <br /></p>
                <ul>
                  <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                  <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-nav">
                <h4>navigation</h4>
                <ul className="footer-links">
                  <li><a href="home">home</a></li>
                  <li><a href="about">about us</a></li>
                  <li><a href="services">services</a></li>
                  <li><a href="contact">contact us</a></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-social">
                <h4>follow us</h4>
                <ul className="footer-links">
                <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
                  <li><a href="#">twitter</a></li>
                  <li><a href="#">instagram</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright � Siremar. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
</div>

        );
    }
}

export default Registerinspector;