import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
 
class Updatestatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {order :[], userid: '', full_name:''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount() {
    axios.get('http://backend.pxb0625.uta.cloud/sqlsrv/orders.php/?id=' + this.props.match.params.id)
    .then(response => response.data)
    .then((data) => {
      
      console.log(data);
      this.setState({ order: data })
    })
    .catch(function (error) {
      
      console.log(error);
    })
    axios.get('http://backend.pxb0625.uta.cloud/sqlsrv/inspectors.php/?id=' + this.props.match.params.id)
    .then(response => response.data)
    .then((data) => {
      // handle success
      console.log(data);
      this.setState({  userid: data.user_id, full_name: data.fullname  })
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    
     
     
  }
 
  handleChange(event) {
      const state = this.state
      state[event.target.name] = event.target.value
      this.setState(state);
  }
 
  handleSubmit(event) {
      event.preventDefault();
 
      let formData = new FormData();
      
      formData.append('status', this.state.order_status)
      
 
      axios({
          method: 'post',
          url: 'http://backend.pxb0625.uta.cloud/sqlsrv/orders.php/?id=' + this.props.match.params.id,
          data: formData,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
      })
      .then(function (response) {
          //handle success
          console.log(response)
          if(response.status === 200) {
            alert("order updated successfully.");
          }
      })
      .catch(function (response) {
          //handle error
          console.log(response)
      });
  }

    render() {
        return (
            <div>
  <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
            </div>
              <div className="col-md-8">
                <div className="navbar-header">
                <ul className="nav navbar-nav navbar-right">
                    <li className="menu"><Link to={`/inspector/${this.state.userid}`}>Home</Link> </li>
                    <li className="menu"><Link to={`/profile/${this.state.userid}`}>Profile</Link> </li>
                    <li className="active menu"><Link to={`/orders/${this.state.userid}`}></Link> </li>
                    <li className="menu"><Link to={`/usersdata/${this.state.userid}`}>Users</Link> </li>
                    <li className="menu"><Link to={"/login"}>Logout</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          
          <div className="page-title overlay" style={{background: 'url("../images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>update order</h2>
          </div>
          
        </section>
        <section>
          <div className="wrapper">
            <div className="title">
              update
            </div>
            <div className="form">
              <div className="inputfield">
                <label>order_id</label>
                <input  className="input"  value={this.state.order_id} 
                onChange={this.handleChange} />
              </div>
              <div className="inputfield">
                <label>user_id</label>
                <input  className="input"  value={this.state.user_id} 
                onChange={this.handleChange} />
              </div>
              <div className="inputfield">
                <label>service_id</label>
                <input type="text" name="service_id" className="input"  value={this.state.service_id} 
                onChange={this.handleChange} />
              </div>
              <div className="inputfield">
                <label>order_date</label>
                <input type="text" name="order_date" className="input"  value={this.state.order_date} 
                onChange={this.handleChange} />
              </div>
              
              <div className="inputfield">
                <label>status</label>
                <input type="text" name="status" className="input"  value={this.state.order_status} 
                onChange={this.handleChange} />
              </div>
              
              <div className="inputfield">
                <input type="submit" Value="update order" className="btn" onClick={this.handleSubmit} />
              </div>
            </div>
          </div>
        </section>
</div>

        );
    }
}

export default Updatestatus;