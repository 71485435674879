import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
class Inspectors extends Component {
  constructor(props) {
    super(props);
    this.state = {user_id: '', full_name:''};
    
  }

  componentDidMount() {
    axios.get('http://backend.pxb0625.uta.cloud/sqlsrv/inspectors.php/?id=' + this.props.match.params.id)
    .then(response => response.data)
    .then((data) => {
      // handle success
      console.log(data);
      this.setState({  user_id: data.user_id, full_name: data.fullname  })
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
     
  }
 
  
    render() {
        return (
            <div>
         <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
              </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="active menu"><Link to={`/inspector/${this.state.user_id}`}>Home</Link> </li>
                    <li className="menu"><Link to={`/profile/${this.state.user_id}`}>Profile</Link> </li>
                    <li className="menu"><Link to={`/orders/${this.state.user_id}`}>Orders</Link> </li>
                    <li className="menu"><Link to={`/usersdata/${this.state.user_id}`}>Users</Link> </li>
                    <li className="menu"><Link to={"/login"}>Logout</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("../images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Welcome to Margarita</h2>
          </div>
        </section><br /><br /><br />
        <section>
          <div className="slider-content container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                    <Link to={`/registrationform/${this.state.user_id}`}><img src="../images/img/school.jpg" className="img-responsive" alt="" /></Link>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Register Schools</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                    <Link to={`/registrationform/${this.state.user_id}`}><img src="../images/img/flight.jpg" className="img-responsive" alt="" /></Link>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Register Travel Agencies</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                  <Link to={`/registrationform/${this.state.user_id}`}><img src="../images/img/realestate.jpg" className="img-responsive" alt="" /></Link>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Register Real-Estate</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div><br /><br /><br />
            <div className="row">
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                  <Link to={`/registrationform/${this.state.user_id}`}><img src="../images/img/legal.jpg" className="img-responsive" alt="" /></Link>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Register Legal Services</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                  <Link to={`/registrationform/${this.state.user_id}`}><img src="../images/img/tax.jpg" className="img-responsive" alt="" /></Link>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Register Tax-Services</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                  <Link to={`/registrationform/${this.state.user_id}`}><img src="../images/img/doctor.jpg" className="img-responsive" ></img></Link>                  
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h5>Register Hospitals</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section><Link to={`/registrationform/${this.state.user_id}`}><br /><br /><br />
        </Link>
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
            <div className="col-md-4 col-xs-6 about">
                <Link className="navbar-brand" to={"/home"}><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></Link>
                <p> <br />
                     <br />
                    <br /></p>
                <ul>
                    <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                    <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                  </ul>
                </div>
              <div className="col-md-2 col-xs-6 footer-nav">
                  <h4>navigation</h4>
                <ul className="footer-links">
                  <li><Link to={"/home"} >home</Link></li>
                  <li><Link to={"/about"}>about us</Link></li>
                  <li><Link to ={"/services"}>services</Link></li>
                  <li><Link to={"/contact"}>contact us</Link></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-social">
                <h4>follow us</h4>
                <ul className="footer-links">
                <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
                  <li><a href="#">twitter</a></li>
                  <li><a href="#">instagram</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright © Siremar. All Rights Reserved.</p>
            </div>
          </div>
   
            </footer>
</div>

        );
    }
}

export default Inspectors;