import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Aboutus extends Component {
    render() {
        return (
            <div>
  <header className="main">
    <nav className="navbar navbar-default navbar-static-top fluid_header">
      <div className="container">
        <div className="col-md-4">
          <a className="navbar-brand" href="home.html"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
        </div>
        <div className="col-md-8">
          <div className="navbar-header">
            <ul className="nav navbar-nav navbar-right">
              <li className="menu"><a href="/">home</a> </li>
              <li className="active menu"> <a href="about">About Us</a> </li>
              <li className="menu"> <a href="services">Services</a></li>
              <li className="menu"> <a href="contact">Contact Us</a> </li>
              <li className="menu"><a href="login">Login</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <section className="main" id="pages">
    <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
      <h2>About Us</h2>
    </div>
  </section>
  <section id="about">
    <div className="container main-content">
      <div className="col-md-12">
        <h2 className="section-title">What is Siremar</h2>
        <p>Siremar is an establising island, in which all residents of the island sholud register online or offline. So that we will provide an id to the residents. Along with that we also offer many benefits to the residents who got registered.</p>
        <p>There were three types of users and the benefits incluses discounts on flights, businesses, events etc... </p>
        <p>In order to attain those benefits from siremar, all the residents should follow the solicit registration on various events.</p>
      </div>
      <div className="col-md-6 about-img pad40">
        <div className="vid-container">
          <img src="images/img/about-gif.gif" className="img-responsive" alt="" />
        </div>
      </div>
      <div className="col-md-6 pad40">
        <p>
          <br />
          <br />There were three types of users and the benefits incluses discounts on flights, businesses, events etc...
        </p>
        <p>
          <br />
          <br />In order to attain those benefits from siremar, all the residents should follow the solicit registration on various events.
        </p>
      </div>
    </div>
  </section>
  <footer id="main-footer">
    <div className="container">
      <div className="row footer-top">
        <div className="col-md-4 col-xs-6 about">
          <a className="navbar-brand" href="home.html"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
          <p>
            <br />
            <br />
            <br />
          </p>
          <ul>
            <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
            <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
          </ul>
        </div>
        <div className="col-md-2 col-xs-6 footer-nav">
          <h4>navigation</h4>
          <ul className="footer-links">
            <li><a href="/">home</a></li>
            <li><a href="about">about us</a></li>
            <li><a href="services">services</a></li>
            <li><a href="contact">contact us</a></li>
          </ul>
        </div>
        <div className="col-md-2 col-xs-6 footer-social">
          <h4>follow us</h4>
          <ul className="footer-links">
            <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
            <li><a href="#">twitter</a></li>
            <li><a href="#">instagram</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 text-center copyright">
        <p>Copyright © Siremar. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</div>


        );
    }
}

export default Aboutus;