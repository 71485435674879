import axios from 'axios';
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Addevents extends Component {
  constructor(props){
    super(props);
    this.state = {event_name: '',
    place : '',
    event_type: '',
    event_date: '',
    availability :'',
    status:''};
    this.handleChange=this.handleChange.bind(this);
    this.handleSubmit=this.handleSubmit.bind(this);
  }

  handleChange(event){
    
    const state = this.state
    state[event.target.name] =event.target.value
    this.setState(state);
  }
  handleSubmit(event){
    event.preventDefault();
    let formData = new FormData();
    formData.append('event_name', this.state.event_name)
    formData.append('place', this.state.place)
    formData.append('event_type', this.state.event_type)
    formData.append('event_date', this.state.event_date)
    formData.append('availability', this.state.availability)
    formData.append('status', this.state.status)
    console.log(JSON.stringify(formData, null, 2));

    axios({
      method: 'post',
      url: 'http://backend.pxb0625.uta.cloud/sqlsrv/events.php',
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
  })
  .then(function (response) {
      //handle success
      console.log(response)
      alert('New event Successfully Added.');  
      
  })
  .catch(function (response) {
      //handle error
      console.log(response)
  });
 
    
  }



    render() {
        return (
            <div>
  <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
            </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="menu"><a href="admin">Dashboard</a> </li>
                    <li className="active menu"> <a href="addevents">Add Events</a> </li>
                    <li className="menu"> <a href="data">Data</a></li>
                    <li className="menu"><a href="login">Logout</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          
          <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Add Events</h2>
          </div>
          
        </section>
        <section>
          <div className="wrapper">
            <div className="title">
              Events
            </div>
            <div className="form">
              <div className="inputfield">
                <label>Name of the Event</label>
                <input type="text" name="event_name" className="input"  value={this.state.event_name} 
                onChange={this.handleChange} />
              </div>
              <div className="inputfield">
                <label>Place</label>
                <input type="text" name="place" className="input"  value={this.state.place} 
                onChange={this.handleChange} />
              </div>
              <div className="inputfield">
                <label>event_type</label>
                <input type="text" name="event_type" className="input"  value={this.state.event_type} 
                onChange={this.handleChange} />
              </div>
              <div className="inputfield">
                <label>event_date</label>
                <input type="text" name="event_date" className="input"  value={this.state.event_date} 
                onChange={this.handleChange} />
              </div>
              <div className="inputfield">
                <label>availability</label>
                <input type="text" name="availability" className="input"  value={this.state.availability} 
                onChange={this.handleChange} />
              </div>
              <div className="inputfield">
                <label>status</label>
                <input type="text" name="status" className="input"  value={this.state.status} 
                onChange={this.handleChange} />
              </div>
              
              <div className="inputfield">
                <input type="submit" Value="Add Event" className="btn" onClick={this.handleSubmit} />
              </div>
            </div>
          </div>
        </section>
</div>

        );
    }
}

export default Addevents;