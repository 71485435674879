import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import axios from 'axios';
class Adminusersdata extends Component {
  constructor(props){
    super(props);
    this.state = {users: []};
    this.headers=[
      
      {key: 'user_id', lable: 'user_id'},
      {key: 'username', lable: 'user name'},
      {key: 'email', lable: 'email'},
      
      {key: 'contact', lable: 'Contact'},
      
      
    ];
  }

  componentDidMount(){
    const url = 'http://backend.pxb0625.uta.cloud/sqlsrv/adminusers.php'
    axios.get(url).then(response => response.data)
    .then((data) => {
      this.setState({ users: data})
      console.log(this.state.users)
    })
    
  }


    render() {
     
        return (
            <div>
   <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
            </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="menu"><a href="admin">Dashboard</a> </li>
                    <li className="menu"> <a href="addevents">Add Events</a> </li>
                    <li className="active menu"> <a href="data">Data</a></li>
                    <li className="menu"><a href="login">Logout</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("../images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Users Data</h2>
          </div>
        </section>
        <section>
          <table className="content-table">
            <thead>
              <tr>
                {
                    this.headers.map(function(h){
                      return(
                        <th key={h.key}>{h.lable}</th>
                      )

                    })
                }


              </tr>
            </thead>
            <tbody>
                {
                    this.state.users.map(function(item, key){
                        return(
                        
                            <tr key={key}>
                                <td>{item.user_id}</td>
                                <td>{item.username}</td>
                                <td>{item.email}</td>
                                <td>{item.contact}</td>
                                
                                
                            </tr>
                        )}.bind(this))
                }
            </tbody>
          </table>
        </section>
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
            <div className="col-md-4 col-xs-6 about">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
              <p> <br />
                <br />
                <br /></p>
              <ul>
                  <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                  <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-nav">
                <h4>navigation</h4>
                <ul className="footer-links">
                  <li><a href="home">home</a></li>
                  <li><a href="about">about us</a></li>
                  <li><a href="services">services</a></li>
                  <li><a href="contact">contact us</a></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-social">
                <h4>follow us</h4>
                <ul className="footer-links">
                  <li><a href="#">blog</a></li>
                  <li><a href="#">twitter</a></li>
                  <li><a href="#">instagram</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright � Siremar. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
</div>
)

    
}

    
}


export default Adminusersdata;