import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Home extends Component {
    render() {
        return (
            <div>
  <header className="main">
    <nav className="navbar navbar-default navbar-static-top fluid_header">
      <div className="container">
        <div className="col-md-4">
          <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
        </div>
        <div className="col-md-8">
          <div className="navbar-header">
            <ul className="nav navbar-nav navbar-right">
              <li className="active menu"><a href="/">home</a> </li>
              <li className="menu"> <a href="about">About Us</a> </li>
              <li className="menu"> <a href="services">Services</a></li>
              <li className="menu"> <a href="contact">Contact Us</a> </li>
              <li className="menu"><a href="login">Login</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <section className="main" id="home">
    <div className="fullscreen overlay" id="index-image">
      <div className="home-content container">
        <div className="col-md-12">
          <h3>Welcome to <br /><span>Margarita</span></h3>
          <h4>A Beautiful <span>Island</span> located in South America!</h4>
          <div className="cta">
            <a href="login" className="btn">Get Startred</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="about-us">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 about-image">
        </div>
        <div className="col-md-8 main-content">
          <h2 className="section-title">About Us</h2>
          <div className="about-description">
            <p>Siremar is an establising island, in whicha all residents of the island sholud register online or offline. So that we will provide an id to the residents. Along with that we also offer many benefits... </p>
            <a href="about" className="btn">Read more</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="benefits">
    <div className="container-fluid">
      <div className="col-md-12 main-content">
        <h2 className="section-title">Benefits</h2>
      </div>
      <div>
        <div className="row">
          <div className="col-md-5 col-sm-6 col-xs-12 user-benefits">
            <div className="pull-right">
              <a href="#0">
                <img src="images/icons/ferry.png" alt="" />
              </a>
              <h4>Book Ferry Tickets</h4>
            </div>
          </div>
          <div className="col-md-5 col-md-offset-2 col-sm-6 col-xs-12 user-benefits">
            <div className="pull-left">
              <a href="#0">
                <img src="images/icons/travelling.png" alt="" />
              </a>
              <h4>Discounts on Flight Tickts</h4>
            </div>
          </div>
        </div>
        <div className="row pad40">
          <div className="col-md-3 col-sm-6 col-xs-12 user-benefits">
            <div className="pull-right">
              <a href="#0">
                <img src="images/icons/school.png" alt="" />
              </a>
              <h4>School Amissions</h4>
            </div>
          </div>
          <div className="col-md-6 benefit-image">
            <img src="images/img/benefits-1.gif" className="img-responsive" alt="" />
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 user-benefits active">
            <div className="pull-left">
              <a href="#0">
                <img src="images/icons/balloons.png" alt="" />
              </a>
              <h4>Discounts on Event Tickets</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="service">
    <div className="container main-content">
      <div className="col-md-12">
        <h2 className="section-title">Services</h2>
        <p className="section-descr">There are many types of services that offer companies convenience, luxury and expertise. Some are essential to nearly all businesses, while others are rewarding services that improve work satisfaction..</p>
      </div>
      <div>
        <article className="col-md-12 serviceblog-post services">
          <div className="col-md-3 serviceblog-thumbnail">
            <a href="#"><img src="images/img/real-estate.png" className="img-responsive" alt="" /></a>
          </div>
          <div className="col-md-9 serviceblog-desc">
            <h4><a href="#">REAL ESTATE SERVICES</a></h4>
            <p>
              <br />
              <br />Many companies rent their office or retail space instead of owning it. Real estate agents help companies find workspaces and arrange rental aggrements.
            </p>
            <p>
              <br />
              <a href="login" className="btn">Book Now</a>
            </p>
          </div>
        </article>
      </div>
      <div className="cta">
        <a href="services" className="btn">Read More</a>
      </div>
    </div>
  </section>
  <section id="signup">
    <div className="container">
      <div className="col-md-6 col-md-offset-2">
        <h3 className="section-title">signup <strong>now</strong> and <br /> avail <strong>Benefits</strong> </h3>
      </div>
      <div className="col-md-4">
        <a href="register" className="btn">signup</a>
      </div>
    </div>
  </section>
  <footer id="main-footer">
    <div className="container">
      <div className="row footer-top">
        <div className="col-md-4 col-xs-6 about">
          <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
          <p> <br />
            <br />
            <br /></p>
          <ul>
            <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
            <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
          </ul>
        </div>
        <div className="col-md-2 col-xs-6 footer-nav">
          <h4>navigation</h4>
          <ul className="footer-links">
            <li><a href="/">home</a></li>
            <li><a href="about">about us</a></li>
            <li><a href="services">services</a></li>
            <li><a href="contact">contact us</a></li>
          </ul>
        </div>
        <div className="col-md-2 col-xs-6 footer-social">
          <h4>follow us</h4>
          <ul className="footer-links">
          <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
            <li><a href="#">twitter</a></li>
            <li><a href="#">instagram</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 text-center copyright">
        <p>Copyright © Siremar. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</div>

        );
    }
}

export default Home;