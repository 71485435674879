import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
class Resident extends Component {
  constructor(props) {
    super(props);
    this.state = {user_id: '', full_name:''};
    
  }

  componentDidMount() {
    axios.get('http://backend.pxb0625.uta.cloud/sqlsrv/users.php/?id=' + this.props.match.params.id)
    .then(response => response.data)
    .then((data) => {
      
      console.log(data);
      this.setState({  user_id: data.user_id, full_name: data.fullname  })
    })
    .catch(function (error) {
      
      console.log(error);
    })
    
     
  }
 
  

    render() {
        return (
            <div>
  <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <Link className="navbar-brand" to="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></Link>
              </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                  <li className="active menu"><Link to={`/resident/${this.state.id}`}>Home</Link> </li>
                    <li className="menu"> <Link to={`/userprofile/${this.state.id}`}>Profile</Link> </li>
                    <li className="menu"> <Link to={`/services1/${this.state.id}`}>Services</Link></li>
                    
                    <li className="menu"><Link to={'/login'}>Logout</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("../images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Hello {this.state.full_name}</h2>
          </div>
        </section><br /><br /><br />
        <section>
          <div className="slider-content container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                    <Link to={`/events/${this.state.user_id}`}><img src="../images/img/ongoing-events.jpg" className="img-responsive" alt="" /></Link>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Ongoing Events</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                    <Link to={`/travel/${this.state.user_id}`}><img src="../images/img/book-tickets.jpg" className="img-responsive" alt="" /></Link>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Book Tickets</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                    <Link to ={`/services1/${this.state.user_id}`}><img src="../images/img/services.jpg" className="img-responsive" alt="" /></Link>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Book Services</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section><br /><br /><br />
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
            <div className="col-md-4 col-xs-6 about">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
                <p> <br />
                     <br />
                    <br /></p>
                <ul>
                  <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                  <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-nav">
                <h4>navigation</h4>
                <ul className="footer-links">
                  <li><a href="home">home</a></li>
                  <li><a href="about">about us</a></li>
                  <li><a href="services">services</a></li>
                  <li><a href="contact">contact us</a></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-social">
                <h4>follow us</h4>
                <ul className="footer-links">
                <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
                  <li><a href="#">twitter</a></li>
                  <li><a href="#">instagram</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright � Siremar. All Rights Reserved.</p>
            </div>
          </div>
         </footer>
</div>

        );
    }
}

export default Resident;