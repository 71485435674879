import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Services extends Component {
    render() {
        return (
<div>
  <header className="main">
    <nav className="navbar navbar-default navbar-static-top fluid_header">
      <div className="container">
        <div className="col-md-4">
           <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
        </div>
        <div className="col-md-8">
          <div className="navbar-header">
            <ul className="nav navbar-nav navbar-right">
              <li className="menu"><a href="/">home</a> </li>
              <li className="menu"> <a href="about">About Us</a> </li>
              <li className="active menu"> <a href="services">Services</a></li>
              <li className="menu"> <a href="contact">Contact Us</a> </li>
              <li className="menu"><a href="login">Login</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <section className="main" id="pages">
    <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
      <h2>Services</h2>
    </div>
  </section>
  <section id="serviceblog">
    <div className="container">
      <article className="col-md-12 serviceblog-post services">
        <div className="col-md-3 serviceblog-thumbnail">
          <a href="#"><img src="images/img/real-estate.png" className="img-responsive" alt="" /></a>
        </div>
        <div className="col-md-9 serviceblog-desc">
          <h4><a href="#">REAL ESTATE SERVICES</a></h4>
          <p>
            <br />
            <br />Many companies rent their office or retail space instead of owning it. Real estate agents help companies find workspaces and arrange rental aggrements.
          </p>
          <a href="login" className="btn">Book Now</a>
        </div>
      </article>
      <article className="col-md-12 serviceblog-post services">
        <div className="col-md-3 serviceblog-thumbnail">
          <a href="#"><img src="images/img/hospital.png" className="img-responsive" alt="" /></a>
        </div>
        <div className="col-md-9 serviceblog-desc">
          <h4><a href="#">HOSPITAL SERVICES</a></h4>
          <p>
            <br />
            <br />Some companies hire health and wellness experts, such as nutritionists, therapists or other doctors, to promote good health for their employees. These health services are available to the compnay's employees if they are feeling unwell. Having these services can help encourage good health practices and ultimately improve workplace satisfaction.
          </p>
          <a href="login" className="btn">Book Now</a>
        </div>
      </article>
      <article className="col-md-12 serviceblog-post services">
        <div className="col-md-3 serviceblog-thumbnail">
          <a href="#"><img src="images/img/school-img.jpg" className="img-responsive" alt="" /></a>
        </div>
        <div className="col-md-9 serviceblog-desc">
          <h4><a href="#">EDUCATION SERVICES</a></h4>
          <p>
            <br />
            <br />Siremer provides the free of cose education for all the child residents living on the island. It makes sure that each and every child on the island should pursue his/her education till 12th grade free of cost. Siermer main motive is to see there is no illitracy rate on the island.
          </p>
          <a href="login" className="btn">Book Now</a>
        </div>
      </article>
      <article className="col-md-12 serviceblog-post services">
        <div className="col-md-3 serviceblog-thumbnail">
          <a href="#"><img src="images/img/law.jpg" className="img-responsive" alt="" /></a>
        </div>
        <div className="col-md-9 serviceblog-desc">
          <h4><a href="#">LEGAL SERVICES</a></h4>
          <p>
            <br />
            <br />A company may want to hire legal services if they need to draft business aggrements, ask for legal advice or navigate a legal issue. Legal teams or individuals can offer guidance, advice and expertise to a company to ensure its legally binding documents uphold the organization's original intentions.'
          </p>
          <a href="login" className="btn">Book Now</a>
        </div>
      </article>
      <article className="col-md-12 serviceblog-post services">
        <div className="col-md-3 serviceblog-thumbnail">
          <a href="#"><img src="images/img/taxes.jpg" className="img-responsive" alt="" /></a>
        </div>
        <div className="col-md-9 serviceblog-desc">
          <h4><a href="#">TAX SERVICES</a></h4>
          <p>
            <br />
            <br />Companies often hire financial professionals to help prepare tax documents, budgets and audits. These financial services allow companies to understand their financial position and ensure that their fiscal practices are beneficial.
          </p>
          <a href="login" className="btn">Book Now</a>
        </div>
      </article>
      <article className="col-md-12 serviceblog-post services">
        <div className="col-md-3 serviceblog-thumbnail">
          <a href="#"><img src="images/img/travel.jpg" className="img-responsive" alt="" /></a>
        </div>
        <div className="col-md-9 serviceblog-desc">
          <h4><a href="#">TRAVEL AGENCY</a></h4>
          <p>
            <br />
            <br />When an employee is required to travel for work, it is customary for the company to provide travel arrangements. Travel services can make this process much simpler. These services can help ensure travel plans are accurate and cost-efficient.
          </p>
          <a href="login" className="btn">Book Now</a>
        </div>
      </article>
    </div>
  </section>
  <footer id="main-footer">
    <div className="container">
      <div className="row footer-top">
      <div className="col-md-4 col-xs-6 about">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
                <p> <br />
                    <br />
                    <br /></p>
                <ul>
            <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
            <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
          </ul>
        </div>
        <div className="col-md-2 col-xs-6 footer-nav">
          <h4>navigation</h4>
          <ul className="footer-links">
            <li><a href="/">home</a></li>
            <li><a href="about">about us</a></li>
            <li><a href="services">services</a></li>
            <li><a href="contact">contact us</a></li>
          </ul>
        </div>
        <div className="col-md-2 col-xs-6 footer-social">
          <h4>follow us</h4>
          <ul className="footer-links">
          <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
            <li><a href="#">twitter</a></li>
            <li><a href="#">instagram</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 text-center copyright">
        <p>Copyright © Siremar. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</div>

        );
    }
}

export default Services;