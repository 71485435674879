import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
class Phpdata2 extends Component {
  constructor(props){
    super(props);
    this.state = {first_name: '', last_name: '', gender: '', email: '', contact: '', address: '', postal_code: ''}
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event){
    const state = this.state
    state[event.target.name] = event.traget.value
    this.setState(state);
  }
  handleSubmit(event) {
    event.preventDefault();
    let formData = new FormData();
    formData.append('first_name', this.state.first_name)
    formData.append('last_name', this.state.last_name)
    formData.append('gender', this.state.gender)
    formData.append('email', this.state.email)
    formData.append('contact', this.state.contact)
    formData.append('address', this.state.address)
    formData.append('postal_code', this.state.postal_code)

    axios({
      method : 'post',
      url: 'http://backend.pxb0625.uta.cloud/sqlsrv/php1.php',
      data: formData,
      config: {headers: {'Content-Type': 'multipart/form-data'}}
    })

    .then(function (response) {
      console.log(response)
      alert("registered successfully")

    })
    .catch(function(response){
      console.log(response)
    });

  }
    render() {
        return (
            <div>
 <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
            </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="menu"><a href="home">home</a> </li>
                    <li className="menu"> <a href="about">About Us</a> </li>
                    <li className="menu"> <a href="services">Services</a></li>
                    <li className="menu"> <a href="contact">Contact Us</a> </li>
                    <li className="active menu"><a href="login">Login</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          
          <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Get Started</h2>
          </div>
          
        </section>
        <section>
          <div className="wrapper">
            <div className="title">
              Registration Form
            </div>
            <div className="form">
              
              <div className="inputfield">
                <label>First Name</label>
                <input type="text" className="input" value={this.state.first_name} onChange={this.handleChange.bind(this)}/>
              </div>
              <div className="inputfield">
                <label>Last Name</label>
                <input type="text" className="input" value={this.state.last_name} onChange={this.handleChange}/>
              </div>
              <div className="inputfield">
                <label>Gender</label>
                <div className="custom_select">
                  <select value={this.state.gender} onChange={this.handleChange}>
                    <option value>Select</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
              </div>
              <div className="inputfield">
                <label>Email Address</label>
                <input type="text" className="input" value={this.state.email} onChange={this.handleChange}/>
              </div>
              <div className="inputfield">
                <label>Phone Number</label>
                <input type="text" className="input" value={this.state.contact} onChange={this.handleChange}/>
              </div>
              <div className="inputfield">
                <label>Address</label>
                <textarea className="textarea" defaultValue={""} value={this.state.address} onChange={this.handleChange}/>
              </div>
              <div className="inputfield">
                <label>Postal Code</label>
                <input type="text" className="input" value={this.state.postal_code} onChange={this.handleChange}/>
              </div>
              <div className="inputfield terms">
                <label className="check">
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
                <p>Agreed to terms and conditions</p>
              </div>
              <div className="inputfield">
                <input type="submit" defaultValue="Register" className="btn" onSubmit={this.handleSubmit}/>
              </div>
              <p>Already have an account. <a href="login"> Login here</a><br /></p>
              
            </div>
          </div>
        </section>
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
            <div className="col-md-4 col-xs-6 about">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
                <p> <br />
                     <br />
                    <br /></p>
                <ul>
                  <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                  <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-nav">
                <h4>navigation</h4>
                <ul className="footer-links">
                  <li><a href="home">home</a></li>
                  <li><a href="about">about us</a></li>
                  <li><a href="services">services</a></li>
                  <li><a href="contact">contact us</a></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-social">
                <h4>follow us</h4>
                <ul className="footer-links">
                <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
                  <li><a href="#">twitter</a></li>
                  <li><a href="#">instagram</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright � Siremar. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
</div>

        );
    }
}

export default Phpdata2;