import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Servicesdata extends Component {
    render() {
        return (
            <div>
  <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
            </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="menu"><a href="admin">Dashboard</a> </li>
                    <li className="menu"> <a href="addevents">Add Events</a> </li>
                    <li className="active menu"> <a href="data">Data</a></li>
                    <li className="menu"><a href="login">Logout</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Data</h2>
          </div>
        </section><br /><br /><br />
        <section>
          <div className="slider-content container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                    <a href="adminusersdata"><img src="images/img/user.jpg" className="img-responsive" alt="" /></a>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Users Data</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                    <a href="inspectorsdata"><img src="images/img/inspector.png" className="img-responsive" alt="" /></a>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Inspectors Data</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                
                <div className="featured-icon-box style16 mb-30">
                  <div className="featured-icon">
                    <a href="servicesdata"><img src="images/img/servicesicon.png" className="img-responsive" alt="" /></a>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h5>Services Data</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div><br /><br /><br />
          </div>
        </section><br /><br /><br />
</div>

        );
    }
}

export default Servicesdata;