import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
class Events extends Component {

  constructor(props){
    super(props);
    this.state = {events : [],user_id: '', full_name:''};
        this.headers=[
      {key: 'event_name', lable: 'Name'},
      {key: 'place', lable: 'Place'},
      {key: 'event_type', lable: 'Event Type'},
      {key: 'availability', lable: 'Availability'},
      {key: 'status', lable: 'Status'},
      {key: 'event_date', lable: 'Date'},
      
      
    ];
    this.handleSubmit=this.handleSubmit.bind(this);
  }

  componentDidMount(){
   
    axios.get('http://backend.pxb0625.uta.cloud/sqlsrv/events.php')
    .then(response => response.data)
    .then((data) => {
      
      console.log(data)
      this.setState({ events: data})
      
    })
    
    
    axios.get('http://backend.pxb0625.uta.cloud/sqlsrv/users.php/?id=' + this.props.match.params.id)
    .then(response => response.data)
    .then((data) => {
      
      console.log(data);
      this.setState({  user_id: data.user_id, full_name: data.fullname  })
    })
    .catch(function (error) {
      
      console.log(error);
    })
    
  
  
}

handleSubmit(data) {
  
    
  let formData = new FormData();
  formData.append('user_id', this.state.user_id)
  formData.append('event_id', this.state.event_id)
  formData.append('event_name', this.state.event_name)
  

  axios({
    method: 'post',
    url: 'http://backend.pxb0625.uta.cloud/sqlsrv/addorder.php',
    data: formData,
    config: { headers: {'Content-Type': 'multipart/form-data' }}
}).then(response => response.data)
.then((data) => {
  
  console.log(data)
  if(data){
    alert('event booked successfully');
  }
     
    
})
.catch(function (response) {
    
    console.log(response)
});

}   
  
    render() {
        return (
            <div>
      <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
              </div>
              <div className="col-md-8">
                <div className="navbar-header">
                <ul className="nav navbar-nav navbar-right">
                  <li className="active menu"><Link to={`/resident/${this.state.user_id}`}>Home</Link> </li>
                    <li className="menu"> <Link to ={`/userprofile/${this.state.user_id}`}>profile</Link> </li>
                    <li className="menu"> <Link to={`/services1/${this.state.user_id}`}>Services</Link></li>
                   
                    <li className="menu"><Link to ={`/login`}>Logout</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("../images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Hello {this.state.full_name}</h2>
          </div>
        </section>
        <section>
          <table className="content-table">
            <thead>
            <tr>
              {
                    this.headers.map(function(h){
                      return(
                        <th key={h.key}>{h.lable}</th>
                        
                      )

                    })
                }
                <th>Book</th>
              </tr>
            </thead>
            <tbody>
            {
                    this.state.events.map(function(item, key){
                      return(
                      
                          <tr key={key}>
                            
                             <td >{item.event_name} </td>
                              <td>{item.place}</td>
                              <td>{item.event_type}</td>
                              <td>{item.availability}</td>
                              <td>{item.status}</td>
                              <td>{item.event_date}</td>
                             
                              <td><a href="#" className="btn" onClick={this.handleSubmit}>Book Now</a></td>
                              
                          </tr>
                            
                        )}.bind(this))

                }
            </tbody>
          </table>
        </section>
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
            <div className="col-md-4 col-xs-6 about">
          <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
          <p> <br />
            <br />
            <br /></p>
          <ul>
                  <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                  <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-nav">
                <h4>navigation</h4>
                <ul className="footer-links">
                  <li><a href="home">home</a></li>
                  <li><a href="about">about us</a></li>
                  <li><a href="services">services</a></li>
                  <li><a href="contact">contact us</a></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-social">
                <h4>follow us</h4>
                <ul className="footer-links">
                <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
                  <li><a href="#">twitter</a></li>
                  <li><a href="#">instagram</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright � Siremar. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
</div>

        );
    }
}

export default Events;