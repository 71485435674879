import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
class Orders extends Component {
  constructor(props){
    super(props);
    this.state = {orders: [],user_id: '', full_name:''};
    this.headers=[
      {key: 'order_id', lable: 'order_id'},  
      {key: 'user_id', lable: 'user_id'},
      {key: 'service_id', lable: 'service_id'},
      {key: 'order_date', lable: 'order_date'},
      {key: 'order_status', lable: 'order_status'},
      
      
    ];
    this.handleSubmit=this.handleSubmit.bind(this);
  }
  
  componentDidMount(){
    const url = 'http://backend.pxb0625.uta.cloud/sqlsrv/orders.php'
    axios.get(url).then(response => response.data)
    .then((data) => {
      this.setState({ orders: data})
      console.log(this.state.orders)
    })
    axios.get('http://backend.pxb0625.uta.cloud/sqlsrv/inspectors.php/?id=' + this.props.match.params.id)
    .then(response => response.data)
    .then((data) => {
      // handle success
      console.log(data);
      this.setState({  user_id: data.user_id, full_name: data.fullname  })
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    
     
  }
  handleChange(event){

  }

  handleSubmit(data) {
    
      
    let formData = new FormData();
    formData.append('order_id', this.state.order_id)
    formData.append('order_status', this.order_status)
    
    

    axios({
      method: 'post',
      url: 'http://backend.pxb0625.uta.cloud/sqlsrv/orders.php/?id=' + this.state.order_id,
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
  }).then(response => response.data)
  .then((data) => {
    
    console.log(data)
    if(data=='ok'){
      alert('order updated successfully');
    }
       
      
  })
  .catch(function (response) {
      
      console.log(response)
  });
  
	}    
    
  

    render() {
        return (
            <div>
        <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
              </div>
              <div className="col-md-8">
                <div className="navbar-header">
                <ul className="nav navbar-nav navbar-right">
                    <li className="menu"><Link to={`/inspector/${this.state.user_id}`}>Home</Link> </li>
                    <li className="menu"><Link to={`/profile/${this.state.user_id}`}>Profile</Link> </li>
                    <li className="active menu"><Link to={`/orders/${this.state.user_id}`}>Orders</Link> </li>
                    <li className="menu"><Link to={`/usersdata/${this.state.user_id}`}>Users</Link> </li>
                    <li className="menu"><Link to={"/login"}>Logout</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("../images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Orders </h2>
          </div>
        </section>
        <section>
          <table className="content-table">
            <thead>
              <tr>
              {
                    this.headers.map(function(h){
                      return(
                        <th key={h.key}>{h.lable}</th>
                        
                      )

                    })
                }
                <th>Change status</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
            {
                    this.state.orders.map(function(item, key){
                        return(
                        
                            <tr key={key}>
                                <td>{item.order_id}</td>
                                <td>{item.user_id}</td>
                                <td>{item.service_id}</td>
                                <td>{item.order_date}</td>
                                <td>{item.order_status}</td>
                                <td><select name="order_status" id="order_status" 
                                      onChange={e =>item.order_status=e.target.value}>
                                  <option value="initiated">Initiated</option>
                                  <option value="inprogress">In progress</option>
                                  <option value="completed">Completed</option>
                                  
                                  </select>
                                </td>
                                <td>
                                  <Link  className='btn'onClick={this.handleSubmit}>Update</Link>
                                </td>
                                
                                
                            </tr>
                            
                        )}.bind(this))

                }
            </tbody>
          </table>
        </section>
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
            <div className="col-md-4 col-xs-6 about">
                <Link className="navbar-brand" to={"/home"}><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></Link>
                <p> <br />
                     <br />
                    <br /></p>
                <ul>
                    <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                    <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                  </ul>
                </div>
              <div className="col-md-2 col-xs-6 footer-nav">
                  <h4>navigation</h4>
                <ul className="footer-links">
                  <li><Link to={"/home"} >home</Link></li>
                  <li><Link to={"/about"}>about us</Link></li>
                  <li><Link to ={"/services"}>services</Link></li>
                  <li><Link to={"/contact"}>contact us</Link></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-social">
                <h4>follow us</h4>
                <ul className="footer-links">
                <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
                  <li><a href="#">twitter</a></li>
                  <li><a href="#">instagram</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright © Siremar. All Rights Reserved.</p>
            </div>
          </div>
   
            </footer>
</div>

        );
    }
}


export default Orders;