import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {id:'', full_name:'',email_id:'', contact:'', address:''};
    
  }

  componentDidMount() {
    axios.get('http://backend.pxb0625.uta.cloud/sqlsrv/inspectors.php/?id=' + this.props.match.params.id)
    .then(response => response.data)
    .then((data) => {
      this.setState({id:data.user_id, full_name:data.full_name,email_id:data.email, contact: data.contact, address: data.address})
      console.log(data);
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      
    });
     
  }
    render() {
        return (
            <div>
       <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
              </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className=" menu"><Link to={`/inspector/${this.state.id}`}>Home</Link> </li>
                    <li className="active menu"><Link to={`/profile/${this.state.id}`}>Profile</Link> </li>
                    <li className="menu"><Link to={`/orders/${this.state.id}`}>Orders</Link> </li>
                    <li className="menu"><Link to={`/usersdata/${this.state.id}`}>Users</Link> </li>
                    <li className="menu"><Link to={"/login"}>Logout</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("../images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Profile</h2>
          </div>
        </section><br /><br /><br />
        <section id="profile">
          <div className="profile">
            <div className="card">
              <img src="../images/img/test.svg" alt="" />
              <h2>My Profile</h2>
              <div className="cont">
                <p>
                  
                  full_name : {this.state.full_name} <br></br>
                  Email_id: {this.state.email_id}<br></br>
                  contact: {this.state.contact}<br></br>
                  address: {this.state.address}<br></br>

                </p>
                
              </div>
            </div>
          </div>
        </section><br /><br /><br />
        <section id="profile">
          <div className="profile">
                     
          </div>
        </section>
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
            <div className="col-md-4 col-xs-6 about">
                <Link className="navbar-brand" to={"/home"}><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></Link>
                <p> <br />
                     <br />
                    <br /></p>
                <ul>
                    <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                    <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                  </ul>
                </div>
              <div className="col-md-2 col-xs-6 footer-nav">
                  <h4>navigation</h4>
                <ul className="footer-links">
                  <li><Link to={"/home"} >home</Link></li>
                  <li><Link to={"/about"}>about us</Link></li>
                  <li><Link to ={"/services"}>services</Link></li>
                  <li><Link to={"/contact"}>contact us</Link></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-social">
                <h4>follow us</h4>
                <ul className="footer-links">
                <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
                  <li><a href="#">twitter</a></li>
                  <li><a href="#">instagram</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright © Siremar. All Rights Reserved.</p>
            </div>
          </div>
   
            </footer>
</div>

        );
    }
}

export default Profile;