import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
class Servicesdata extends Component {
  constructor(props){
    super(props);
    this.state = {services : []};
    
    this.headers=[
      {key: 'service_id', lable: 'Id'},
      {key: 'service_owner', lable: 'Name'},
      {key: 'address', lable: 'Address'},
      {key: 'contact', lable: 'contact'},
      {key: 'availability', lable: 'availability'},
      
      
    ];
    
  }
  

  componentDidMount(){
   
    axios.get('http://localhost:8080/sqlsrv/admin_serv.php')
    .then(response => response.data)
    .then((data) => {
      
      console.log(data)
      this.setState({ services: data})


      
    })
    
    
  }
    render() {
        return (
            <div>
 <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
            </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="menu"><a href="admin">Dashboard</a> </li>
                    <li className="menu"> <a href="addevents">Add Events</a> </li>
                    <li className="active menu"> <a href="data">Data</a></li>
                    <li className="menu"><a href="login">Logout</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Admin</h2>
          </div>
        </section>
        <section>
          <table className="content-table">
            <thead>
            <tr>
              {
                    this.headers.map(function(h){
                      return(
                        <th key={h.key}>{h.lable}</th>
                        
                      )

                    })
                }
                
              </tr>
            </thead>
            <tbody>
            {
                    this.state.services.map(function(item, key){
                        return(
                        
                            <tr key={key}>
                               <td >{item.service_id} </td>
                               <td >{item.service_owner} </td>
                                <td>{item.address}</td>
                                <td>{item.contact}</td>
                                <td>{item.availability}</td>
                               
                                
                                
                            </tr>
                            
                        )}.bind(this))

                }
            </tbody>


          </table>
        </section>
</div>

        );
    }
}

export default Servicesdata;