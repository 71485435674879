import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
class Userprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {id:'', full_name:'',email_id:'', username:''};
    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    axios.get('http://backend.pxb0625.uta.cloud/sqlsrv/users.php/?id=' + this.props.match.params.id)
    .then(response => response.data)
    .then((data) => {
      this.setState({id:data.user_id, full_name:data.fullname,email_id:data.email, username:data.username})
      console.log(data);
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
     
  }
    render() {
        return (
            <div>
  <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <Link className="navbar-brand" to="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></Link>
              </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                  <li className="menu"><Link to={`/resident/${this.state.id}`}>Home</Link> </li>
                    <li className="active menu"> <Link to={`/userprofile/${this.state.id}`}>Profile</Link> </li>
                    <li className="menu"> <Link to={`/services1/${this.state.id}`}>Services</Link></li>
                    
                    <li className="menu"><Link to={'/login'}>Logout</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("../images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Profile</h2>
          </div>
        </section><br /><br /><br />
        <section id="profile">
          <div className="profile">
            <div className="card">
              <img src="../images/img/test.svg" alt="" />
              <h2>My Profile</h2>
              <div className="cont">
                <p>
                  user_Name : {this.state.username}<br></br>
                  full_name : {this.state.full_name} <br></br>
                  Email_id: {this.state.email_id}<br></br>

                </p>
                
              </div>
            </div>
          </div>
        </section><br /><br /><br />
        <section id="profile">
          <div className="profile">
                     
          </div>
        </section>
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
              <div className="col-md-12">
              <div className="col-md-4 col-xs-6 about">
                <Link className="navbar-brand" to="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></Link>
                <p> <br />
                     <br />
                    <br /></p>
                <ul>
                    <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                    <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                  </ul>
                </div>
                <div className="col-md-4  footer-nav">
                  <h4>navigation</h4>
                  <ul className="footer-links">
                    <li><Link to="home">home</Link></li>
                    <li><Link to="about">about us</Link></li>
                    <li><Link to="services">services</Link></li>
                    <li><Link to="contact">contact us</Link></li>
                  </ul>
                </div>
                <div className="col-md-4  footer-social">
                  <h4>follow us</h4>
                  <ul className="footer-links">
                  <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
                    <li><Link to="#">twitter</Link></li>
                    <li><Link to="#">instagram</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright © Siremar. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
</div>
        );
    }
}

export default Userprofile;