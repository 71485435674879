import React, { Component } from 'react';
import {useState,useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {ResponsiveContainer, LineChart, Line, XAxis, YAxis,CartesianGrid, Tooltip, Legend} from 'recharts';



  

class Admin1 extends Component {

  constructor(props){
    super(props);
    this.state = {services :[], graph :[], orders:[]}
    
  }
  
  componentDidMount(){

    const url = 'http://backend.pxb0625.uta.cloud/sqlsrv/admin_orders.php'
    axios.get(url).then(response => response.data)
    .then((data) => {
      this.setState({ orders: data})
      console.log(this.state.orders)
    })
    

    const url2= 'http://backend.pxb0625.uta.cloud/sqlsrv/admin_graph.php'
    axios.get(url2).then(response => response.data)
    .then((data) => {
      this.setState({ services: data[0], graph: data[1] })
      console.log(this.state.services)
      console.log(this.state.graph)
    })
  
 
  }
  
  
  
  
    render() {
        const data = [
            {
              name: this.state.services[0],
              Inprogress : this.state.graph[0],
              count : [1,2,3,4,5,6],
              
            },
            
            {
                name: this.state.services[1],
                Inprogress : this.state.graph[1],
                count : [1,2,3,4,5,6],
                
            },
            {
                name: this.state.services[2],
                Inprogress : this.state.graph[2],
                count : [1,2,3,4,5,6],
                
            },
            {
                name: this.state.services[3],
                Inprogress : this.state.graph[3],
                count : [1,2,3,4,5,6],
                
            },
            {
                name: this.state.services[4],
                Inprogress : this.state.graph[4],
                count : [1,2,3,4,5,6],
                
            },
            {
                name: this.state.services[5],
                Inprogress : this.state.graph[5],
                count : [1,2,3,4,5,6],
                
            },
            {
                name: this.state.services[6],
                Inprogress : this.state.graph[6],
                count : [1,2,3,4,5,6],
                
            }

        ];
        return (
            <div>
          <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
              <div className="col-md-4">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
              </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="active menu"><a href="admin">Dashboard</a> </li>
                    <li className="menu"> <a href="addevents">Add Events</a> </li>
                    <li className="menu"> <a href="data">Data</a></li>
                    <li className="menu"><a href="login">Logout</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Admin</h2>
          </div>
        </section>
        <section>
          <div className="container-widget">
            
            <div className="col-md-12">
              <ul className="topstats clearfix">
                <li className="col-xs-4">
                  <span className="title"> Users</span>
                  <h3>960</h3>
                </li>
                <li className="col-xs-4">
                  <span className="title"> Services Used</span>
                  <h3 className="color-up">1520</h3>
                </li>
                <li className="col-xs-4 ">
                  <span className="title">Inspectors</span>
                  <h3>96</h3>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <div className="charts">
            <div className="chart">
              <h2>Services Booked</h2>
              <ResponsiveContainer width="90%" height="90%">
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                   }}
                      >
                      <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis dataKey="count"/>
                        <Tooltip />
                        <Legend />
                       <Line type="monotone" dataKey="Inprogress" stroke="#3cebee" activeDot={{ r: 8 }} />
                       
                </LineChart>
             </ResponsiveContainer>
              
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="featured-icon-box style16 mb-30" style={{width: '400px', height: '500px', borderRadius: '30px'}}>
                <div className="featured-icon">
                  <a href="Registerinspector"><img src="../images/img/inspector.png" className="img-responsive" alt="" /></a>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h5><br />Register Inspector</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
        </section>
        
        
        
</div>

        );
      
    }
}

export default Admin1;