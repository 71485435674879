import React, { Component ,useState,useContext} from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
class Login1 extends Component {
   
  constructor(props){
		super(props)
    
		this.state={
			userEmail:'',
			userPassword:'',
      
      auth1: false,
      auth2: false,
      auth3: false
      
		}
    this.handleChange=this.handleChange.bind(this);
    this.handleSubmit=this.handleSubmit.bind(this);
    
    
	}
	handleChange(event){
    
    const state = this.state
    state[event.target.name] =event.target.value
    this.setState(state);
    
  }
	handleSubmit(event){
    event.preventDefault();
    let formData = new FormData();
    formData.append('userEmail', this.state.userEmail)
    formData.append('userPassword', this.state.userPassword)
    console.log(JSON.stringify(formData, null, 2));
    
    
		
    axios.post(
      
      'http://backend.pxb0625.uta.cloud/sqlsrv/login.php',
       formData,{
      config: { headers: {'Content-Type': 'multipart/form-data',
      'Accept': 'application/json' }}
  }).then(response => response.data)
  .then((data) => {
    
    console.log(data)
    if(data == "user"){
      alert('Login Successful');
 
        this.setState({ auth1: true})
      }else if(data == "insp"){
        this.setState({ auth2: true})
      }else if(data == 'Admin'){
        this.setState({ auth3: true})
      }
      
      
    
      else {
        alert('Wrong Details');
        
      }
      
       
      
  })
  .catch(function (response) {
      //handle error
      console.log(response)
  });
  
	}    
      
		
    render() {
      
     if(this.state.auth1){
       
        return <Redirect to = {`/resident/${this.state.user_id}`}/>
       }else if (this.state.auth2){
        return <Redirect to = {`/inspector`}/>
       }else if(this.state.auth3){
        return <Redirect to = {`/admin`}/>
       }
     
      
        return (
<div>
  <header className="main">
    <nav className="navbar navbar-default navbar-static-top fluid_header">
      <div className="container">
      <div className="col-md-4">
          <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
      </div>
        <div className="col-md-8">
          <div className="navbar-header">
            <ul className="nav navbar-nav navbar-right">
              <li className="menu"><a href="/">home</a> </li>
              <li className="menu"> <a href="about">About Us</a> </li>
              <li className="menu"> <a href="services">Services</a></li>
              <li className="menu"> <a href="contact">Contact Us</a> </li>
              <li className="active menu"><a href="login">Login</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <section className="main" id="pages">
   
    <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
      <h2>Welcome</h2>
    </div>
    
  </section>
  <section>
    <div className="wrapper">
      <div className="title">
        Login
      </div>
      <div className="form">
        <div className="inputfield">
          <label>Login Id</label>
          <input type="text" name="userEmail" className="input"  value={this.state.userEmail} 
                onChange={this.handleChange} />
        </div>
        <div className="inputfield">
          <label >Password</label>
          <input type="text" name="userPassword" className="input"  value={this.state.userPassword} 
                onChange={this.handleChange} />
          
        </div>
        
        <div className="inputfield terms">
          <label className="check">
            <input type="checkbox" />
            <span className="checkmark" />
          </label>
          <p>Remember me</p>
        </div>
        <div className="inputfield">
          <input type="submit" Value="Login" className="btn" onClick={this.handleSubmit}/>
        </div>
        <p> <a href="forgotpassword"> Forgot Password </a><br /></p>
        <p>Don't have an account. <a href="register"> Register here</a><br /></p>
      </div>
    </div>
  </section>
  <footer id="main-footer">
    <div className="container">
      <div className="row footer-top">
      <div className="col-md-4 col-xs-6 about">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
                <p> <br />
                     <br />
                    <br /></p>
                <ul>
            <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
            <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
          </ul>
        </div>
        <div className="col-md-2 col-xs-6 footer-nav">
          <h4>navigation</h4>
          <ul className="footer-links">
            <li><a href="/">home</a></li>
            <li><a href="about">about us</a></li>
            <li><a href="services">services</a></li>
            <li><a href="contact">contact us</a></li>
          </ul>
        </div>
        <div className="col-md-2 col-xs-6 footer-social">
          <h4>follow us</h4>
          <ul className="footer-links">
          <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
            <li><a href="#">twitter</a></li>
            <li><a href="#">instagram</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 text-center copyright">
        <p>Copyright � Siremar. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</div>

        );
    }
}


export default Login1;