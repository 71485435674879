import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

class Readdata extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(data,{resetForm}) {
    console.log(JSON.stringify(data, null, 2));
      resetForm({formData: " "})
    let formData = new FormData();
    formData.append('service name', data.service_name)
    formData.append('service owner', data.service_owner)
    formData.append('email id', data.email_id)
    formData.append('service type', data.service_type)
    formData.append('address', data.address)
    formData.append('contact', data.contact)

    axios({
      method: 'post',
      url: 'http://backend.pxb0625.uta.cloud/sqlsrv/service_registration.php',
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
  }).then(response => response.data)
  .then((data) => {
    
    console.log(data)
    if(data == 'ok'){
      alert('service registered successfully');
    
      
      
      }
      else {
        alert('Email already present');
        
      }
      
       
      
  })
  .catch(function (response) {
      //handle error
      console.log(response)
  });
  
	}    
  /*.then(function (response) {
      //handle success
      console.log(response)
      if(response.json()=='not ok'){
      alert('New User Successfully Added.');  
      }
  })
  .catch(function (response) {
      //handle error
      console.log(response)
  });
 
}*/

validationSchema() {
  return Yup.object().shape({
    service_name: Yup.string().required('service name is required'),
    service_owner: Yup.string()
      .required('service owner is required')
      .min(6, 'name must be at least 6 characters')
      .max(20, 'name must not exceed 20 characters'),
    email_id: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    address: Yup.string()
      .required('address is required'),
    service_type: Yup.string()
      .required('service type is required'),
      
    contact: Yup.string()
      .required('Contact is required')
      
    
    
  });
}



    
    render() {
      const initialValues = {
        service_name: '',
       service_owner: '',
        email_id: '',
        address: '',
        service_type: '',
        contact: '',
        acceptTerms: false,
    };
        return (
            <div>
 <header className="main">
          <nav className="navbar navbar-default navbar-static-top fluid_header">
            <div className="container">
            <div className="col-md-4">
              <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
            </div>
              <div className="col-md-8">
                <div className="navbar-header">
                  <ul className="nav navbar-nav navbar-right">
                    <li className="menu"><a href="home">home</a> </li>
                    <li className="menu"> <a href="about">About Us</a> </li>
                    <li className="menu"> <a href="services">Services</a></li>
                    <li className="menu"> <a href="contact">Contact Us</a> </li>
                    <li className="active menu"><a href="login">Login</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="main" id="pages">
          
          <div className="page-title overlay" style={{background: 'url("images/img/bg-img.jpg")', backgroundSize: 'cover'}}>
            <h2>Register Services</h2>
          </div>
          
        </section>
        <section>
          <div className="wrapper">
            <div className="title">
              Registration Form
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}>
 
                {({ resetForm }) => (
                <Form>
            <div className="form">
              <div className="inputfield">
                <label htmlFor='service_name'>service_name</label>
                <Field name="service_name" type="text" className="input"/>
                <ErrorMessage name="service_name" component="div" className="text-danger"/>
                    
              </div>
              <div className="inputfield">
              <label htmlFor="service_owner"> service owner </label>
                <Field name="service_owner" type="text" className="input"/>
                <ErrorMessage name="service_owner" component="div" className="text-danger"/>
                   
              </div>
              
              <div className="inputfield">
              <label htmlFor="email_id"> Email id </label>
                <Field name="email_id" type="email" className="input"/>
                <ErrorMessage name="email_id" component="div" className="text-danger"/>
              </div>
              <div className="inputfield">
                <label htmlFor="service_type">service type</label>
                <Field component="select" name="service_type"  className="input" placeholder="select service type">
                
                <option value>select service type</option>
                <option value={4}>Schools</option>
                <option value={3}>hospitals</option>
                <option value={5}>Legal service</option>
                <option value={6}>Tax service</option>
                <option value={2}>Travel Agency</option>
                <option value={1}>Real-Estate service</option>
              
                <ErrorMessage name="service_type" component="div" className="text-danger"/>
                </Field>
              </div>
              
              <div className="inputfield">
                <label htmlFor="contact">contact</label>
                <Field name="contact" type="text" className="input"/>
                <ErrorMessage name="contact" component="div" className="text-danger"/>
              </div>
              <div className="inputfield">
                <label htmlFor="address">address</label>
                <Field name="address" type="text" className="input"/>
                <ErrorMessage name="address" component="div" className="text-danger"/>
              </div>
              <div className="inputfield terms">
              <Field name="acceptTerms" type="checkbox" className="checkmark"/>
              <label htmlFor="acceptTerms" className="checkmark">
                       agree to the Terms
                </label>
                <ErrorMessage name="acceptTerms" component="div" className="text-danger"/>
                
              </div>
              <div className="inputfield">
              <button type="submit"  className="btn">
                        Register
                      </button>
              <button type="button" onClick={resetForm} className="btn">
                 Reset
              </button>
              </div>
              
            </div>
            </Form>
                )}
                </Formik>
          </div>
        </section>
        <footer id="main-footer">
          <div className="container">
            <div className="row footer-top">
            <div className="col-md-4 col-xs-6 about">
                <a className="navbar-brand" href="home"><h1 style={{color: '#3cbeee', fontStyle: 'oblique'}}>Siremar</h1></a>
                <p> <br />
                     <br />
                    <br /></p>
                <ul>
                  <li><span><i className="fa fa-map-marker" />New York City, USA</span></li>
                  <li><span><i className="fa fa-phone" />(123) 456 789 0012</span></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-nav">
                <h4>navigation</h4>
                <ul className="footer-links">
                  <li><a href="home">home</a></li>
                  <li><a href="about">about us</a></li>
                  <li><a href="services">services</a></li>
                  <li><a href="contact">contact us</a></li>
                </ul>
              </div>
              <div className="col-md-2 col-xs-6 footer-social">
                <h4>follow us</h4>
                <ul className="footer-links">
                <li><a href="http://siremar.pxb0625.uta.cloud/">blog</a></li>
                  <li><a href="#">twitter</a></li>
                  <li><a href="#">instagram</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>Copyright � Siremar. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
</div>

        );
    }
}

export default Readdata;