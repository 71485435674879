import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Route, HashRouter, Link, BrowserRouter as Router, Switch } from 'react-router-dom';
// import { browserHistory } from 'react-router';
import { AnimatedSwitch } from 'react-router-transition';
import ScrollToTop from 'react-router-scroll-top'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './components/Home';
import Header from './components/Header';
import Aboutus from './components/Aboutus';
import Services from './components/Services';
import Contact from './components/Contact';
import Login from './components/Login';
import Register from './components/Register';
import Realestate from './components/Realestate';
import Resident from './components/Resident';
import Events from './components/Events';
import Services1 from './components/Services1';
import Travel from './components/Travel';
import Userprofile from './components/Userprofile';
import Admin from './components/Admin';
import Adminusersdata from './components/Adminusersdata';
import Addevents from './components/Addevents';
import Inspectorsdata from './components/Inpectorsdata';
import Servicesdata from './components/Servicesdata';
import Data from './components/Data';
import Inspectors from './components/Inspectors';
import Usersdata from './components/Usersdata';
import Registrationform from './components/Registrationform';
import Profile from './components/Profile';
import Hospitals from './components/Hospitals';
import Schools from './components/Schools';
import Tax from './components/Tax';
import Legal from './components/Legal';
import Orders from './components/Orders';
import Updatestatus from './components/Updatestatus';
import Forgotpassword from './components/Forgotpassword';
import Phpdata from './components/Phpdata';
import Phpdata2 from './components/Phpdata2';
import Readdata from './components/Readdata';
import Login1 from './components/Login1';
import Registerinspector from './components/Registerinspectors';
import Admin1 from './components/Admin1'
// import './style.scss';

const routing = (

  <Router history={HashRouter} >
    
    <ScrollToTop>

      <div>
        <Header />
      
        <Switch>
          <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >     
          <Route  exact path="/" component={Home} />
          <Route path="/about" component={Aboutus} />
          <Route path="/services" component={Services} />
          <Route path="/contact" component={Contact} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/realestate/:id" component={Realestate} />
          <Route path="/resident/:id" component={Resident} />
          <Route path="/events" component={Events} />
          <Route path="/services1/:id" component={Services1} />
          <Route path="/travel/:id" component={Travel} />
          <Route path="/userprofile/:id" component={Userprofile} />
          <Route path="/admin" component={Admin} />
          <Route path="/registerinspector" component={Registerinspector} />
          <Route path="/adminusersdata" component={Adminusersdata} />
          <Route path="/addevents" component={Addevents} />
          <Route path="/inspectorsdata" component={Inspectorsdata} />
          <Route path="/servicesdata" component={Servicesdata} />
          <Route path="/data" component={Data} />
          <Route path="/inspector/:id" component={Inspectors} />
          <Route path="/usersdata/:id" component={Usersdata} />
          <Route path="/profile/:id" component={Profile} />          
          <Route path="/registrationform" component={Registrationform} />
          <Route path="/hospitals/:id" component={Hospitals} />
          <Route path="/schools/:id" component={Schools} />
          <Route path="/orders/:id" component={Orders} />
          <Route path="/edit/:id" component={Updatestatus} />
          <Route path="/tax/:id" component={Tax} />
          <Route path="/legal/:id" component={Legal} />
          <Route path="/forgotpassword" component={Forgotpassword}/>
          <Route path="/phpdata" component={Phpdata}/>
          <Route path="/phpdata2" component={Phpdata2}/>
          <Route path="/readdata" component={Readdata}/>
          <Route path="/login1" component={Login1}/>
          <Route path="/admin1" component={Admin1}/>
           
          


          </AnimatedSwitch>     
        </Switch>
       
      </div>
    </ScrollToTop>
    
  </Router >
)
ReactDOM.render(routing, document.getElementById('root'));


reportWebVitals();
